<template>
  <v-container class="d-flex flex-column justify-center ">
    <v-container id="section" class="terms">
      <div class="text-h4">Handelsbetingelser</div>
      <div class="text-body-1">Handelsbetingelser hos Create Space</div>
    </v-container>
    <v-container id="section" class="general-info">
      <div class="text-h4">Generelle oplysinger</div>
      <v-list class="text-body-1">
        <v-list-item>Create Space</v-list-item>
        <v-list-item>Bülowsgade 39, st.</v-list-item>
        <v-list-item>8000 Aarhus C</v-list-item>
        <v-list-item>CVR nr.: 43775286</v-list-item>
        <v-list-item>Telefon: +45 26791836</v-list-item>
        <v-list-item>Email: nikolinelumbye@createspace.dk</v-list-item>
      </v-list>
    </v-container>
    <v-container id="section" class="payment">
      <div class="text-h4">Betaling</div>
      <div class="text-body-1">Vi modtager betaling med kreditkort og Mobilepay. Er der tale om bestillingsarbejde vil
        betalingen
        blive
        trukket, når aftalen er godkendt.<br>
        Er der tale om workshops o.l. vil betalingen blive trukket, når køber har modtaget en bekræftelse pr. mail.
        Alle beløb er i DKK. Danske kroner.
      </div>
    </v-container>
    <v-container id="section" class="delivery">
      <div class="text-h4">Levering</div>
      <div class="text-body-1">En bestilling vil blive sendt med GLS, DAO eller Bring så snart produktet er færdigt. Da
        der er tale om
        håndarbejde, varierer det meget, hvor længe der går før bestillingen kan afsendes.
      </div>
    </v-container>
    <v-container id="section" class="complaint">
      <div class="text-h4">Reklamationsret</div>
      <div class="text-body-1">I henhold til købeloven gives der 2 års reklamationsret. Reklamationsretten gælder for
        fejl i materiale,
        afvigelser fra design og/eller fabrikation. Du kan få varen repareret, ombyttet, pengene retur eller afslag i
        prisen, afhængig af den konkrete situation. Hver sag afgøres altid individuelt.<br>
        Reklamationen gælder ikke fejl eller skader begået ved forkert håndtering af produktet/ydelsen.
        Forkert håndtering indebærer for eksempel forkert opbevaring, der kan give skade, forkert benyttelse af
        produktet i forhold til den tilsigtede anvendelse m.v. <br>
        Du skal reklamere i "rimelig tid" efter du har opdaget manglen/fejlen. Rimelig tid defineres som at være
        inden for de første 14 dage, efter at varen er modtaget, eller straks efter at fejlen er opdaget. Går varen i
        stykker på trods af korrekt håndtering af produktet, gælder reklamationsretten.<br>
        Vi vil dække returneringsomkostninger i et rimeligt omfang. Returnering skal aftales på forhånd. Der
        modtages ikke forsendelser pr. efterkrav.<br>
      </div>
    </v-container>
    <v-container id="section" class="refusion">
      <div class="text-h4">Refusion</div>
      <div class="text-body-1">Hvis der er tale om refusion, bedes du medsende bankoplysninger i form af reg.nr. og
        kontonummer, så det
        aftalte beløb kan overføres til din konto. <br>
        Oplysningerne kan uden risiko oplyses pr. mail eller anden elektronisk form, da det ikke er følsomme
        oplysninger og kun vil blive anvendt til vores opfyldelse af refusionen. Vores e-mails behandles kun af
        ansatte af virksomheden, som er under tavshedspligt.
      </div>
    </v-container>
    <v-container id="section" class="return-rights">
      <div class="text-h4">Returret</div>
      <div class="text-body-1 pb-2">Der gives 14 dages fuld returret på varer købt i webshoppen eller via Instagram med
        mindre der er tale om
        et online kursus, forløb eller workshops. Ved disse bortfalder fortrydelsesretten jf. købeloven så snart købet
        er bekræftet.
      </div>
      <div class="pb-2">
        Perioden regnes fra den dag hvor du;
      </div>
      <div class="text-body-1">
        <ul style="list-style-position: outside">
          <li>Får den sidste vare i fysisk besiddelse, når det drejer sig om en aftale om flere forskellige varer,
            bestilt i én ordre, men leveret enkeltvis eller af flere omgange.
          </li>
          <li>Får det sidste parti, eller sidste del i fysisk besiddelse, når det drejer sig om levering af varer, der
            består af flere partier/dele.
          </li>
          <li>Den første vare i fysisk besiddelse, når det drejer sig om regelmæssig levering af varer over en
            bestemt periode.
          </li>
        </ul>
      </div>

      <div class="text-body-1">
        Returneringsomkostninger i forhold til fragt er købers ansvar, medmindre der vedlægges returlabels i din
        forsendelse. Når du returnerer, er du ansvarlig for, at varen er pakket ordentligt ind. I pakken skal der
        vedlægges en kopi af ordrebekræftelsen. Vedlæg gerne vores Fortrydelsesformular for hurtigere
        ekspedition.
      </div>
      <div class="text-body-1">
        Fortrydelse skal anmeldes til os senest 14 dage efter købet og fra fortrydelsen skal du senest 14 dage efter
        returnere forsendelsen. Meddelelse om fortrydelse skal gives pr. mail. I meddelelsen skal du gøre tydeligt
        opmærksom på, at du ønsker at benytte din fortrydelsesret.
      </div>
      <div class="text-body-1">
        Ønsker du at sende varen retur til os, skal du udfylde den vedlagte Returseddel og sende varen tilbage.
        Du kan ikke fortryde ved blot at nægte modtagelse af varen uden samtidig at give tydelig meddelelse til os
        angående fortrydelsen. Vi modtager ikke pakker sendt pr. efterkrav.
      </div>
      <div class="text-body-1">
        Du bærer risikoen for beskadigelse af varen fra tidspunktet for varens levering hos dig, og til vi har
        modtaget den.
      </div>
    </v-container>
    <v-container id="section" class="items-exception">
      <div class="text-h4">Varer undtaget fortrydelsesretten</div>
      <div class="text-body-1">
        Følgende varetyper indgår ikke i fortrydelsesretten:
      </div>
      <div class="text-body-1">
        <ul style="list-style-position: outside">
          <li>
            Varer, som er fremstillet efter forbrugerens specifikationer eller har fået et tydeligt personligt
            præg.
          </li>
          <li>
            Forseglede varer, som af sundhedsbeskyttelses- eller hygiejnemæssige årsager ikke er egnet til at
            blive returneret, og hvor forseglingen er brudt efter leveringen.
          </li>
          <li>
            Varer, der grundet sin art bliver uløseligt blandet sammen med andre ved levering.
          </li>
          <li>
            Varer, hvor plomberingen er brudt.
          </li>
          <li>
            Udførte ikke-finansielle tjenesteydelser, hvis levering af tjenesteydelsen er påbegyndt med
            forbrugerens forudgående udtrykkelige samtykke og anerkendelse af, at fortrydelsesretten
            ophører, når tjenesteydelsen er fuldt udført.
          </li>
          <li>
            Levering af digitalt indhold, som ikke leveres på et fysisk medium, hvis udførelsen er påbegyndt
            med forbrugerens forudgående udtrykkelige samtykke om, at vedkommende mister sin
            fortrydelsesret ved bekræftelse af køb.
          </li>
          <li>
            Sessioner som for eksempel rådgivning, coaching eller terapi, hvis minimum de første 20 minutter
            af sessionen er afholdt eller hvis klienten ikke er mødt op til aftalen, og ikke har aflyst mindst 24
            timer forinden.
          </li>
          <li>
            Udsalgsvarer, hvor der tydeligt er blevet gjort opmærksom på, at der ikke er fortrydelsesret
          </li>
          <li>
            Aviser, tidsskrifter eller magasiner dog undtaget abonnementsaftaler for sådanne publikationer.
          </li>
          <li>
            Aftaler indgået på offentlig auktion.
          </li>
          <li>
            Varer, der forringes eller forældes hurtigt, som for eksempel fødevarer.
          </li>
        </ul>
      </div>
    </v-container>
    <v-container id="section" class="item-condition">
      <div class="text-h4">Varens stand ved returnering</div>
      <div class="text-body-1">
        Du hæfter kun for eventuel forringelse af varens værdi, som skyldes anden håndtering, end hvad der er
        nødvendigt for at undersøge varens art, egenskaber og den måde, hvorpå den fungerer. Du kan med andre
        ord prøve varen, som hvis du prøvede den i en fysisk butik.
      </div>
      <div class="text-body-1">
        Hvis varen er prøvet udover, det der ovenfor beskrives, betragtes den som brugt. Dette betyder, at du ved
        fortrydelse af købet kun får en del eller intet af købsbeløbet retur, afhængig af varens stand og
        handelsmæssige værdi, når vi får varen retur. For at få hele købsbeløbet retur, er du derfor velkommen til
        at afprøve varen uden egentlig at tage den i brug.
      </div>
    </v-container>
    <v-container id="section" class="back-payment">
      <div class="text-h4">Tilbagebetaling</div>
      <div class="text-body-1">
        Fortryder du dit køb, får du beløbet, du har overført til os, retur.
      </div>
      <div class="text-body-1">
        I tilfælde af en værdiforringelse, som du hæfter for, fratrækkes det fra købsbeløbet.
      </div>
      <div class="text-body-1">
        Ved anvendelse af fortrydelsesretten, refunderes alle betalinger modtaget fra dig, herunder
        leveringsomkostninger (undtaget ekstra omkostninger som følge af dit valg af en anden leveringsform end
        den billigste form for standardlevering, som vi tilbyder), uden unødig forsinkelse og senest 14 dage fra den
        dato, hvor vi har modtaget meddelelse om din beslutning om at gøre brug af fortrydelsesretten.
        Tilbagebetaling gennemføres med samme betalingsmiddel, som du benyttede ved den oprindelige
        transaktion, medmindre du udtrykkeligt har givet udtryk for noget andet.
      </div>
      <div class="text-body-1">
        Vi tilbageholder tilbagebetalingen af beløbet, indtil vi har fået varen retur, medmindre du inden da har
        fremlagt dokumentation for at have returneret varen.
      </div>
    </v-container>
    <v-container id="section" class="complaint-options">
      <div class="text-h4">Klagemuligheder</div>
      <div class="text-body-1">
        En klage over en vare eller tjenesteydelse kan indgives via Klageportalen til Center for Klageløsning,
        Nævnenes Hus, Toldboden 2, 8800 Viborg.
      </div>
      <div class="text-body-1">
        Hvis du er forbruger med bopæl i et andet EU-land, kan du angive din klage i EU Kommissionens online
        klageplatform.
      </div>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "DesignView"
}
</script>

<style scoped>
li {
  padding-bottom: 1em;
  margin-left: 1em
}
div .text-body-1 {
  padding-bottom: 1em;
}
</style>