import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {store, key} from './store'
import vuetify from './plugins/vuetify'

import 'material-design-icons-iconfont/dist/material-design-icons.css'
import {loadFonts} from './plugins/webfontloader'

loadFonts()

createApp(App)
    .use(router)
    .use(store, key)
    .use(vuetify)
    .mount('#app')
