
import "vue-router/dist/vue-router";

export default {
  name: "AppNavigationCard",
  props: {
    backgroundImage: {
      required: true,
      type: String
    },
    title: {
      required: true,
      type: String
    },
    navpath: {
      required: true,
      type: String
    }
  },
  setup() {
    const getBackgroundImage = (name: string) => {
      return require(`@/assets/images/${name}_blurred.jpg`);
    };

    return {getBackgroundImage};
  }
}

