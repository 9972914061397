<template>
  <v-container class="d-flex flex-column justify-center ">
    <v-container id="section" class="cookies">
      <div class="text-h4">Hvad er en cookie?</div>
      <div class="text-body-1">
        En cookie er en lille datafil, som vi gemmer på din computer for at kunne holde styr på, hvad der sker under
        dit besøg og for at kunne genkende computeren. En cookie er ikke et program og indeholder ikke virus.
      </div>
      <div class="text-body-1">
        Når du besøger createspace.dk indsamles der oplysninger om dig, som bruges til at tilpasse og forbedre
        vores indhold. Hvis du ikke ønsker, at der indsamles oplysninger, bør du slette dine cookies og undlade
        videre brug af websitet.
      </div>
      <div class="text-body-1">
        Hvis du sletter eller blokerer cookies vil annoncer kunne blive mindre relevante for dig og optræde
        hyppigere. Du kan desuden risikere at websitet ikke fungerer optimalt samt at der er indhold, du ikke kan få
        adgang til.
      </div>

    </v-container>
    <v-container id="section" class="privacy">
      <div class="text-h4">Privatlivspolitik</div>
      <div class="text-body-1">
        Create Space videresælger ikke personlige oplysninger og videregiver ikke dine personlige oplysninger til
        andre, de er kun registreret i vores kundekartotek. Du kan til enhver tid få slettet dine oplysninger.
      </div>
      <div class="text-body-1">
        For at du kan handle med Create Space, har vi brug for følgende oplysninger: Navn, adresse,
        telefonnummer og e-mail adresse.
      </div>
      <div class="text-body-1">
        Vi foretager registreringen af dine personoplysninger med det formål, at kunne levere varen til dig.
      </div>
      <div class="text-body-1">
        Personoplysningerne registreres hos Create Space og opbevares i op til fem år, hvorefter oplysningerne
        slettes. Når der indsamles personoplysninger via vores hjemmeside, sikrer vi, at det altid sker ved afgivelse
        af dit udtrykkelige samtykke, således at du er informeret om præcis, hvilke oplysninger, der indsamles og
        hvorfor.
      </div>
      <div class="text-body-1">
        Som registreret hos Create Space har du altid ret til at gøre indsigelse mod registreringen. Du har også ret
        til indsigt i, hvilke oplysninger, der er registreret om dig. Disse rettigheder har du efter Persondataloven og
        henvendelse i forbindelse hermed rettes til <a href="mailto: nikolinelumbye@createspace.dk">nikolinelumbye
        @createspace.dk</a>
      </div>
    </v-container>
    <v-container id="section" class="Log-statistics">
      <div class="text-body-1">
        Vi bruger en log-statistik på createspace.dk, hvilket betyder, at et statistik-system opsamler information,
        som kan give et statistisk billede af, hvor mange besøgende vi har, hvor de kommer fra og hvor på websitet
        det forlades mv. Logstatistikken anvendes kun med det formål, at optimere Create Spaces hjemmeside.
      </div>
    </v-container>
    <v-container id="section" class="complaint-options">
      <div class="text-h4">Privatlivspolitik</div>
      <div class="text-body-1">
        Ved klage skal vores e-mail adresse angives: <a
          href="mailto:nikolinelumbye@createspace.dk">nikolinelumbye@createspace.dk</a> samt hjemmesiden du har købt
        varen på.
      </div>
      <div class="text-body-1">
        En klage over en vare eller tjenesteydelse kan indgives til Center for Klageløsning, Nævnenes Hus,
        Toldboden 2, 8800 Viborg. Du kan klage til Center for Klageløsning via <a
          href="https://kpo.naevneneshus.dk/Public/Home/ChooseLoginProvider?returnUrl=https://kpo.naevneneshus.dk/External"
          target="_blank">Klageportalen</a>.
      </div>
      <div class="text-body-1">
        For EU-borgere uden for EU skal klager sendes via EU Kommissionens online klageplatform.
      </div>
    </v-container>
    <v-container id="section" class="information-gathered">
      <div class="text-h4">Oplysninger vi indsamler</div>
      <div class="text-body-1">
        Hvis du ønsker at købe og modtage et produkt eller en ydelse fra os, har vi brug for at indsamle visse
        persondata for at kunne gennemføre handlen og for at kunne tilbyde dig vores services. Vi kan bruge
        cookies til at holde styr på indholdet i din indkøbskurv, mens du bruger webshoppen.
      </div>
      <div class="text-body-1">
        Vi kan indhente oplysninger som f.eks. navn, e-mailadresse, postadresse, leveringsadresse (hvis den
        varierer fra postadresse), telefonnummer og betalingsoplysninger.
      </div>
    </v-container>
    <v-container id="section" class="data-center">
      <div class="text-h4">Andre modtagere af personoplysninger</div>
      <div class="text-body-1">
        Vi sælger ikke dine persondata til tredjemand, og vi overfører ikke dine persondata til tredjelande.
      </div>
      <div class="text-body-1">
        Vi har vores hjemmeside hos Simply og betalingssystem gennem NETS, som fungerer som vores
        databehandler. Alle persondata som du oplyser på vores hjemmeside vil blive opbevaret i Simplys
        datacentre.
      </div>
    </v-container>
    <v-container id="section">
      <div class="text-h4">Dine rettigheder</div>
      <div class="text-body-1">
        Som den registrerede har du en række rettigheder, som vi til enhver tid skal sikre opfyldelse af. Du har ret
        til at anmode os om følgende:
      </div>
      <div class="text-body-1">
        <ul style="list-style-position: outside; ">
          <li>At få adgang til og få rettet/ændret dine persondata</li>
          <li>At få slettet persondata</li>
        </ul>
      </div>
      <div class="text-body-1">
        Du har derudover ret til at protestere over behandlingen af dine personlige data, og du har ret til at indgive
        klage til en databeskyttelsesmyndighed.
      </div>
      <div class="text-body-1">
        Ønsker du ikke længere, at vi skal behandle dine personoplysninger, eller at vi skal begrænse behandlingen
        af dine personoplysninger, kan du også sende os en anmodning herom til vores e-mailadresse
        <a href="mailto:nikolinelumbye@createspace.dk">nikolinelumbye@createspace.dk</a>.
      </div>
    </v-container>
    <v-container id="section">
      <div class="text-h4">Personoplysninger</div>
      <div class="text-body-1">
        Personoplysninger er alle slags informationer, der i et eller andet omfang kan henføres til dig. Når du
        benytter vores website indsamler og behandler vi en række sådanne informationer.
      </div>
      <div class="text-body-1">
        Vi indsamler og behandler typisk følgende typer af oplysninger: Et unikt ID og tekniske oplysninger om din
        computer, tablet eller mobiltelefon, dit IP-nummer, geografisk placering, samt hvilke sider du klikker på
        (interesser). I det omfang du selv giver eksplicit samtykke hertil og selv indtaster informationerne
        behandles desuden: Navn, telefonnummer, e-mail, adresse og betalingsoplysninger. Det vil typisk være i
        forbindelse med et køb.
      </div>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "DesignView"
}
</script>

<style scoped>
li {
  padding-bottom: 1em;
  margin-left: 1em
}

div .text-body-1 {
  padding-bottom: 1em;
}
</style>