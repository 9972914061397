// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import {createVuetify, ThemeDefinition} from 'vuetify'


const createSpaceTheme: ThemeDefinition = {
    dark: false,
    colors: {
        background: '#FCFAF7',
        surface: '#FCFAF7',
        primary: '#FCFAF7',
        'primary-darken-1': '#3700B3',
        secondary: '#03DAC6',
        'secondary-darken-1': '#018786',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00'
    }
}


export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
    {
        theme: {
            defaultTheme: 'createSpaceTheme',
            themes: {
                createSpaceTheme
            }
        }
    }
)
