
import {defineComponent, onMounted, Ref, ref} from "vue";
import { subscribeToProducts } from "@/firebase/methods/subscribe";
import Product from "@/types/Product";
import ProductCard from "@/components/ProductCard.vue";

export default defineComponent({
  name: "GalleryView",
  components: {ProductCard},
  setup() {
    const products: Ref<Product[]> = ref([])


    onMounted(async () => {
      subscribeToProducts(products);
    });

    return {
      products
    }
  },

})
