import {InjectionKey} from 'vue'
import {createStore, Store, useStore as baseUseStore} from 'vuex'
import axios from "axios";

export interface State {
    shoppingCart: {
        items: Map<string, number>,
        subTotal: number,
        paymentId: string,
        customerDetails: {
            firstName: string,
            lastName: string,
            email: string
        },
        paymentActive: boolean,
        discountCode: string
    },
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
    state: {
        shoppingCart: {
            items: new Map<string, number>(),
            subTotal: 0,
            paymentId: '',
            customerDetails: {
                firstName: '',
                lastName: '',
                email: ''
            },
            paymentActive: false,
            discountCode: ''
        },
    },
    getters: {
        getPaymentId(state) {
            return state.shoppingCart.paymentId;
        },
        getCustomerDetails(state) {
            return state.shoppingCart.customerDetails
        },
        getShoppingCart(state): Map<string, number> {
            let shoppingCart = window.localStorage.shoppingCart;
            if (shoppingCart === undefined) {
                shoppingCart = '[]'
            }
            state.shoppingCart.items = new Map<string, number>(JSON.parse(shoppingCart));
            return state.shoppingCart.items;
        },
        isPaymentActive(state) {
            return state.shoppingCart.paymentActive
        },
        isDiscountApplied(state) {
            return state.shoppingCart.discountCode.length > 0
        }
    },
    mutations: {
        async resetDiscountCode(state) {
            state.shoppingCart.discountCode = ''
        },
        async subtotal(state) {
            const convMap: { [key: string]: number } = {};
            store.getters.getShoppingCart.forEach((val: number, key: string) => {
                convMap[key] = val;
            });

            let subtotal = 0;
            await axios.post(`${process.env.VUE_APP_API}/subtotal`, {
                items: convMap,
                discountCode: state.shoppingCart.discountCode
            })
                .then(response => subtotal = response.data)
                .catch(err => console.log('Could not retrieve the subtotal'));
            state.shoppingCart.subTotal = subtotal;
        },
        removeItemFromShoppingCart(state, key: string) {
            store.commit('clearPaymentId')
            state.shoppingCart.discountCode = ''
            const shoppingCart = store.getters.getShoppingCart;
            console.log('STORE: current cart: ', shoppingCart);
            console.log('STORE: removing item with key: ', key);
            state.shoppingCart.items.delete(key)
            window.localStorage.shoppingCart = JSON.stringify(Array.from(state.shoppingCart.items.entries()));
        },
        async addItemToShoppingCart(state, reference: string) {
            window.localStorage.paymentId = ''
            state.shoppingCart.paymentActive = false

            console.log('Adding ', reference, ' to cart.')
            const shoppingCart1 = store.getters.getShoppingCart;

            if (shoppingCart1.has(reference)) {
                console.log('item already in cart.')
                return
            }
            state.shoppingCart.items.set(reference, 1);
            const shoppingCart = window.localStorage.shoppingCart;

            if (shoppingCart === undefined) {
                window.localStorage.shoppingCart = JSON.stringify(Array.from(state.shoppingCart.items.entries()));
            }
            const localStorageCart = JSON.parse(window.localStorage.shoppingCart);
            localStorageCart.push([reference, 1])
            window.localStorage.shoppingCart = JSON.stringify(localStorageCart);

        },
        customerBookingDetails(state, values: Record<string, any>) {
            state.shoppingCart.customerDetails.firstName = values['firstName']
            state.shoppingCart.customerDetails.lastName = values['lastName']
            state.shoppingCart.customerDetails.email = values['email']
        },
        updatePaymentId(state, newId: string) {
            window.localStorage.paymentId = newId
            state.shoppingCart.paymentId = newId
            state.shoppingCart.paymentActive = true
        },
        updateCart(state, cartMap: Map<string, number>) {
            window.localStorage.shoppingCart = JSON.stringify(Array.from(cartMap.entries()));
        },
        clearShoppingCart(state) {
            window.localStorage.shoppingCart = '[]'
        },
        clearPaymentId(state) {
            window.localStorage.paymentId = ''
            state.shoppingCart.paymentActive = false
        },
        resetSubTotal(state) {
            state.shoppingCart.subTotal = 0;
        },
        async applyDiscount(state, discountCode: string): Promise<boolean> {
            const result = await axios.get(`${process.env.VUE_APP_API}/discount/${discountCode}`);
            if (result.data === true) {
                state.shoppingCart.discountCode = discountCode;
                console.log('State discountcode: ', state.shoppingCart.discountCode);
            }
            return result.data;
        }
    },
    actions: {
        clearShoppingCartAndPaymentId({commit}) {
            commit('clearShoppingCart');
            commit('clearPaymentId');
        },
        async paymentCompleted({commit}, paymentId: string) {
            const convMap: { [key: string]: number } = {};
            store.getters.getShoppingCart.forEach((val: number, key: string) => {
                convMap[key] = val;
            });

            const payload = {
                items: convMap,
                reservationDetails: store.state.shoppingCart.customerDetails
            }

            await axios.post(`${process.env.VUE_APP_API}/payment-completed/${paymentId}`, payload)
                .then(response => console.log('STORE actions payment-completed: ', response.data))
        },
        applyDiscount({commit}, discountCode: string) {
            return commit('applyDiscount', discountCode);
        }
    },
    modules: {}
})

// define your own `useStore` composition function
export function useStore() {
    return baseUseStore(key)
}
