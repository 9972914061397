<template>
  <div id="payment-container-div">
  </div>
</template>

<script>

import {onBeforeUnmount, onMounted} from "vue";
import {useStore} from "@/store";

export default {
  name: "AppEmbedded",
  props: {
    paymentId: String,
  },
  setup(props) {

    const store = useStore()
    onBeforeUnmount(() => {
      store.state.shoppingCart.paymentId = ''
    })
    onMounted(() => {
      console.log('mounted embedded wrapper..');
      if (props.paymentId) {
        const checkoutOptions = {
          checkoutKey: process.env.VUE_APP_CHECKOUT_KEY,
          paymentId: props.paymentId,
          containerId: 'payment-container-div'
        }
        // eslint-disable-next-line no-undef
        const checkout = new Dibs.Checkout(checkoutOptions);

        // eslint-disable-next-line no-unused-vars
        checkout.on('payment-completed', function (response) {
          store.dispatch('paymentCompleted', props.paymentId);

          store.dispatch("clearShoppingCartAndPaymentId");
          window.location = 'success'
        })
      }
    });

    return {}
  }
}
</script>

<style scoped>

</style>