import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_container = _resolveComponent("v-container")!
  const _component_ProductCard = _resolveComponent("ProductCard")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      (_ctx.products.length === 0)
        ? (_openBlock(), _createBlock(_component_v_container, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(" Galleriet er tomt lige nu. Nye varer er på vej ")
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_list, {
        modelValue: _ctx.products,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.products) = $event)),
        class: "d-flex justify-center flex-wrap"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (item) => {
            return (_openBlock(), _createBlock(_component_v_list_item, {
              key: item.id
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ProductCard, { product: item }, null, 8, ["product"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}