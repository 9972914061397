import {collection, onSnapshot} from "firebase/firestore";
import {db} from "@/firebase";
import Product from "@/types/Product";
import {Ref} from "vue";
import Workshop from "@/types/Workshop";
import Reservation from "@/types/Reservation";


export const subscribeToProducts = (prop: Ref<Product[]>) => {
    const path = process.env.VUE_APP_PRODUCTS_PATH ?? 'test-products'
    onSnapshot(collection(db, path), (col) => {
        const products: Product[] = [];
        col.forEach(doc => {
            const product: Product = {
                id: doc.id,
                path: doc.ref.path,
                name: doc.data().name,
                unitPrice: doc.data().unitPrice,
                description: doc.data().description,
                height: doc.data().height,
                width: doc.data().width,
                sold: doc.data().sold,
                imagePaths: doc.data().imagePaths,
            }
            if(product.imagePaths[0]) {
                products.push(product);
            }
        })
        prop.value = products
    });
}

export const subscribeToWorkshops = (prop: Ref<Workshop[]>) => {
    const path = process.env.VUE_APP_WORKSHOPS_PATH ?? 'test-workshops'
    onSnapshot(collection(db, path), (col) => {
        const workshops: Workshop[] = [];
        col.forEach(doc => {
            const workshop: Workshop = {
                id: doc.id,
                path: doc.ref.path,
                order: doc.data().order,
                name: doc.data().name,
                unitPrice: doc.data().unitPrice,
                dayOfWeek: doc.data().dayOfWeek,
                date: doc.data().date,
                startTime: doc.data().startTime,
                endTime: doc.data().endTime
            }
                workshops.push(workshop);
        })
        prop.value = workshops
    });
}

export const subscribeToReservations = (prop: Ref<Reservation[]>) => {
    const path = process.env.VUE_APP_RESERVATIONS_PATH ?? 'test-reservations'
    onSnapshot(collection(db, path), (col) => {
        const reservations: Reservation[] = [];
        col.forEach(doc => {
            const reservation: Reservation = {
                firstName: doc.data().firstName,
                lastName: doc.data().lastName,
                email: doc.data().email,
                workshopReferencePath: doc.data().workshopReferencePath,
            }
            reservations.push(reservation);
        })
        prop.value = reservations;
    });
}