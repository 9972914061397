
import {defineComponent, onMounted, PropType, ref} from "vue";
import {getRef} from '@/firebase/methods/storage'
import {getDownloadURL} from 'firebase/storage'
import Product from "@/types/Product";
import {onSnapshot, doc} from "firebase/firestore";
import {db} from "@/firebase";
import {useStore} from "@/store";

export default defineComponent({
  name: "ProductCard",
  props: {
    product: Object as PropType<Product>
  },
  setup(props) {
    const imgSrc = ref('')

    const snackbar = ref(false)

    const reveal = ref(false)
    const toggleReveal = () => reveal.value = !reveal.value;
    const decoratePrice = (number: number) => `pris: ${number} kr.`;

    const store = useStore()
    const addToCart = () => {
      store.commit('addItemToShoppingCart', props.product?.path)
      snackbar.value = true
    }


    onMounted(() => {
      if (props.product !== undefined) {
        onSnapshot(doc(db, 'products', props.product.id), (doc) => {
          if (doc.data && doc.data()?.imagePaths[0] !== undefined) {
            getDownloadURL(getRef(doc.data()?.imagePaths[0]))
                .then(value => {
                  imgSrc.value = value;
                });
          }
        });
      } else {
        console.log('Product was undefined..... yikes')
      }
    });




    return {
      imgSrc,
      reveal,
      snackbar,
      toggleReveal,
      decoratePrice,
      addToCart
    }
  }
})
