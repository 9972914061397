
import AppNavigationCard from "@/components/AppNavigationCard.vue";

export default {
  name: "AppMainNavigationSection",
  setup() {
    const cardData = [
      {background: 'red', title: 'Book workshops', navpath: '/book-workshops'},
      {background: 'lemons', title: 'Galleri', navpath: '/galleri'},
      {background: 'ladybugs', title: 'Design dit eget tæppe', navpath: '/design-dit-eget-taeppe'},
    ];

    return {cardData}
  },
  components: {AppNavigationCard}
}
