import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1783cdac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex justify-center fill-height align-center nav-card-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    to: this.navpath,
    flat: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, { class: "app-nav-card-container pa-0 ma-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_img, {
            cover: "",
            src: $setup.getBackgroundImage($props.backgroundImage)
          }, {
            default: _withCtx(() => [
              _createElementVNode("h2", _hoisted_1, _toDisplayString($props.title), 1)
            ]),
            _: 1
          }, 8, ["src"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["to"]))
}