import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_app_navigation_card = _resolveComponent("app-navigation-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_spacer, { class: "hidden-md-and-down" }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.cardData, (data) => {
            return (_openBlock(), _createBlock(_component_v_col, {
              class: "d-flex justify-center",
              key: data.background
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_navigation_card, {
                  "background-image": data.background,
                  title: data.title,
                  navpath: data.navpath
                }, null, 8, ["background-image", "title", "navpath"])
              ]),
              _: 2
            }, 1024))
          }), 128)),
          _createVNode(_component_v_spacer, { class: "hidden-md-and-down" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}