<template>
  <v-container>
    <v-card density="comfortable">
      <v-card-title>
        Kontakt oplysninger
      </v-card-title>
      <v-card-text class="pa-1">Create Space</v-card-text>
      <v-card-text class="pa-1">Danmark</v-card-text>
      <v-card-text class="pa-1">Email: nikolinelumbye@createspace.dk</v-card-text>
      <v-card-text class="pa-1">Telefon: 26791836</v-card-text>
      <v-card-text class="pa-1">Post adresse: </v-card-text>
      <v-card-text class="pa-1">Bülowsgade 39 st.</v-card-text>
      <v-card-text class="pa-1">8000 Aarhus C</v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "DesignView"
}
</script>

<style scoped>

</style>