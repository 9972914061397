<template>
  <v-footer class="text-center d-flex flex-column" style="background-color: #D8C8F1; max-height: 250px">
    <v-container>
      <v-spacer/>
      <v-container class="d-flex flex-wrap justify-center">
        <div class="mx-4 text-body-1 align-self-center"> © 2023 -
          <strong>Create Space</strong></div>
        <router-link class="mx-4 text-body-2 align-self-center" to="kontakt">Kontakt</router-link>
        <div class="mx-4 text-body-2">CVR 43775286</div>
        <router-link class="mx-4 text-body-2 align-self-center" to="privatlivspolitik">Privatlivspolitik</router-link>
        <router-link class="mx-4 text-body-2 align-self-center" to="handelsbetingelser">Handelsbetingelser og
          -vilkår</router-link>
      </v-container>
      <v-divider></v-divider>
      <v-container class=" pa-0 d-flex flex-wrap justify-center">
        <v-img
            class="ml-2" style="margin-right: -16px"
            max-width="100px"
            src='../assets/images/MP.png'/>
        <v-img
            class="mx-2"
            max-width="35px"
            src='../assets/images/Visa.png'/>
        <v-img
            class="mx-2"
            max-width="35px"
            src='../assets/images/DK_LOGO.png'/>
        <v-img
            class="mx-2"
            max-width="35px"
            src='../assets/images/mc.png'/>
      </v-container>
    </v-container>
  </v-footer>
</template>
<script setup lang="ts">
</script>
<style scoped>
a {
  text-decoration: none !important;
  color: black;
}
</style>