<template>
  <v-container fluid class="d-flex justify-center align-center">
    <div class="text-body-1">Jeg laver modtager bestillinger.
      Kontakt mig via instagram eller mail
      <a href="mailto:nikolinelumbye@createspace.dk">nikolinelumbye@createspace.dk</a>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "DesignView"
}
</script>

<style scoped>

</style>