// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {getFirestore} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
const firebaseConfig = {
    apiKey: "AIzaSyDS7VNxE56I8gCG5PMf6n2GwmWFts4M1HM",
    authDomain: 'createspace-web-app.firebaseapp.com',
    projectId: 'createspace-web-app',
    storageBucket: 'createspace-web-app.appspot.com',
    messagingSenderId: '981100182146',
    appId: '1:981100182146:web:c018bec7635d6f9179d29b',
    measurementId: 'G-9Z77GF6YVK'
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage =  getStorage(app)

export {
    db,
    storage
}