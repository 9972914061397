import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import GalleryView from "@/views/GalleryView.vue";
import DesignView from "@/views/DesignView.vue";
import WorkshopsView from "@/views/WorkshopsView.vue";
import CheckoutView from "@/views/CheckoutView.vue";
import ContactView from "@/views/ContactView.vue";
import TermsView from "@/views/TermsView.vue";
import PrivacyView from "@/views/PrivacyView.vue";
import ShoppingCartView from "@/views/ShoppingCartView.vue";
import SuccessView from "@/views/SuccessView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/galleri',
    name: 'galleri',
    component: GalleryView
  },
  {
    path: '/design-dit-eget-taeppe',
    name: 'design',
    component: DesignView
  },
  {
    path: '/book-workshops',
    name: 'workshops',
    component: WorkshopsView
  },
  {
    path: '/kurv',
    name: 'Kurv',
    component: ShoppingCartView
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: CheckoutView
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    component: ContactView
  },
  {
    path: '/handelsbetingelser',
    name: 'Handelsbetingelser',
    component: TermsView
  },
  {
    path: '/privatlivspolitik',
    name: 'Privatliv',
    component: PrivacyView
  },
  {
    path: '/success',
    name: 'Success',
    component: SuccessView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
