import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8dbe246a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-body-1" }
const _hoisted_2 = { class: "text-body-1" }
const _hoisted_3 = { class: "text-body-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_expand_transition = _resolveComponent("v-expand-transition")!
  const _component_v_snackbar = _resolveComponent("v-snackbar")!

  return (_openBlock(), _createBlock(_component_v_card, {
    loading: _ctx.imgSrc === '',
    width: "250px"
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_component_v_img, {
        key: _ctx.imgSrc,
        id: _ctx.product.id,
        cover: "",
        src: _ctx.imgSrc
      }, null, 8, ["id", "src"])),
      _createVNode(_component_v_btn, {
        width: "100%",
        height: "15px",
        class: "mt-0",
        flat: "",
        onClick: _ctx.toggleReveal
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_icon, null, {
            default: _withCtx(() => [
              _createTextVNode("mdi-chevron-down")
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_v_expand_transition, null, {
        default: _withCtx(() => [
          (_ctx.reveal)
            ? (_openBlock(), _createBlock(_component_v_card, {
                key: 0,
                class: "pa-2 description"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.product.name), 1),
                  _createElementVNode("div", _hoisted_2, "Alt dette til kun: " + _toDisplayString(_ctx.product.unitPrice) + " kr.", 1),
                  _createElementVNode("div", _hoisted_3, "Mål: " + _toDisplayString(_ctx.product.height) + " cm høj & " + _toDisplayString(_ctx.product.width) + " cm bred", 1),
                  _createVNode(_component_v_card_actions, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_spacer),
                      _createVNode(_component_v_btn, {
                        onClick: _ctx.addToCart,
                        loading: _ctx.snackbar
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Læg i kurv")
                        ]),
                        _: 1
                      }, 8, ["onClick", "loading"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_v_snackbar, {
        color: "white",
        modelValue: _ctx.snackbar,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.snackbar) = $event)),
        timeout: 5000
      }, {
        actions: _withCtx(() => [
          _createVNode(_component_v_btn, {
            color: "light-purple",
            variant: "text",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('kurv')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Gå til kurv")
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createTextVNode(" Tilføjet til kurv ")
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["loading"]))
}